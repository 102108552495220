import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./CopyTaskPopup.css";

class CopyTaskV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: null,
      selectFreqOptions: [],
      frequencyId: 0,
      isCopyOnSunday: false,
      isCopyOnMonday: false,
      isCopyOnTuesday: false,
      isCopyOnWednesday: false,
      isCopyOnThursday: false,
      isCopyOnFriday: false,
      isCopyOnSaterday: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    console.log(this.props.trainingItem);
    var daysToCopy = this.props.trainingItem.DaysToCopy;
    if (daysToCopy != null) this.setDaysToCopyFromProps(daysToCopy);

    this.setState({
      selectFreqOptions: [
        {
          id: 0,
          name: "For ever",
        },
        {
          id: 1,
          name: window.lang.week1,
        },
        {
          id: 2,
          name: window.lang.week2,
        },
        {
          id: 3,
          name: window.lang.week3,
        },
        {
          id: 4,
          name: window.lang.week4,
        },
        {
          id: 5,
          name: window.lang.week5,
        },
        {
          id: 6,
          name: window.lang.week6,
        },
        {
          id: 7,
          name: window.lang.week7,
        },
        {
          id: 8,
          name: window.lang.week8,
        },
        {
          id: 9,
          name: window.lang.week9,
        },
        {
          id: 10,
          name: window.lang.week10,
        },
        {
          id: 11,
          name: window.lang.week11,
        },
        {
          id: 12,
          name: window.lang.week12,
        },
      ],
      frequencyId: this.props.trainingItem.CopyDuration,
      task: this.props.trainingItem,
    });
  };

  setDaysToCopyFromProps = (daysToCopy) => {
    daysToCopy = daysToCopy.substring(0, daysToCopy.length - 1);
    var daysToCopyArr = daysToCopy.split("/");
    for (const i in daysToCopyArr) {
      switch (daysToCopyArr[i]) {
        case "0":
          this.state.isCopyOnSunday = true;
          break;
        case "1":
          this.state.isCopyOnMonday = true;
          break;
        case "2":
          this.state.isCopyOnTuesday = true;
          break;
        case "3":
          this.state.isCopyOnWednesday = true;
          break;
        case "4":
          this.state.isCopyOnThursday = true;
          break;
        case "5":
          this.state.isCopyOnFriday = true;
          break;
        case "6":
          this.state.isCopyOnSaterday = true;
          break;
      }
    }
  };

  onFrequencyChange = (e) => {
    this.setState({
      frequencyId: parseInt(e.target.value),
    });
  };

  onCheckboxClick = (e) => {
    this.setState({
      [e.target.dataset.date]: e.target.checked,
    });
  };

  onOkClick = () => {
    if (this.isCopyRuning) return;
    this.isCopyRuning = true;

    let daysToCopy = "";
    if (this.state.isCopyOnSunday) daysToCopy += "0/";
    if (this.state.isCopyOnMonday) daysToCopy += "1/";
    if (this.state.isCopyOnTuesday) daysToCopy += "2/";
    if (this.state.isCopyOnWednesday) daysToCopy += "3/";
    if (this.state.isCopyOnThursday) daysToCopy += "4/";
    if (this.state.isCopyOnFriday) daysToCopy += "5/";
    if (this.state.isCopyOnSaterday) daysToCopy += "6/";

    if (this.state.frequencyId == null) this.state.frequencyId = 0;

    let trainingItem = this.state.task;
    trainingItem.CopyDuration = this.state.frequencyId;
    trainingItem.DaysToCopy = daysToCopy;
    trainingItem.GroupId = trainingItem.id;
    trainingItem.TrainingFlag = window.DataStorage.TrainingFlagUpdate;
    window.DataStorage.addTraining(trainingItem);

    window.training.refresh({ showSpinner: true });
    window.DataGate.copyTraining(
      this.state.task.id,
      this.state.frequencyId,
      daysToCopy,
      (code, data) => {
        //window.training.refresh({ showSpinner: false });
        this.isCopyRuning = false;
        this.onCancelClick();
        if (this.props.doneCallback) this.props.doneCallback();
        window.training.refresh();
      }
    );
  };

  onCancelClick = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("tsWeekDivReact"));
  };

  onRemoveRepetitionClick = () => {
    window.training.refresh({ showSpinner: true });
    window.DataGate.deleteCopyTraining(this.state.task.id, (code, data) => {
      this.onCancelClick();
      if (this.props.doneCallback) this.props.doneCallback();
      window.training.refresh();
    });
  };

  render = () => {
    const {
      selectFreqOptions,
      frequencyId,
      isCopyOnSunday,
      isCopyOnMonday,
      isCopyOnTuesday,
      isCopyOnWednesday,
      isCopyOnThursday,
      isCopyOnFriday,
      isCopyOnSaterday,
    } = this.state;
    return (
      <div className="copy-task ui-page-theme-a" style={{ display: "block" }}>
        <table>
          <tbody>
            <tr>
              <td colSpan="4">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{window.lang.repeatForEach}</span>
                  <div id="placeForSelectFreq" style={{ marginLeft: "5px" }}>
                    <select
                      className="copy-task-select"
                      data-role="none"
                      style={{ width: "100%" }}
                      value={frequencyId}
                      onChange={this.onFrequencyChange}
                    >
                      {selectFreqOptions.map((currentElement, index) => (
                        <option
                          key={currentElement.id}
                          value={currentElement.id}
                        >
                          {currentElement.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: "10%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={isCopyOnSunday}
                    data-date="isCopyOnSunday"
                    onChange={this.onCheckboxClick}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {window.lang.daysNames[0]}
                  </span>
                </div>
              </td>
              <td style={{ width: "10%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCopyOnMonday}
                    data-date="isCopyOnMonday"
                    onChange={this.onCheckboxClick}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {window.lang.daysNames[1]}
                  </span>
                </div>
              </td>
              <td style={{ width: "10%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCopyOnTuesday}
                    data-date="isCopyOnTuesday"
                    onChange={this.onCheckboxClick}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {window.lang.daysNames[2]}
                  </span>
                </div>
              </td>
              <td style={{ width: "10%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCopyOnWednesday}
                    data-date="isCopyOnWednesday"
                    onChange={this.onCheckboxClick}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {window.lang.daysNames[3]}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={isCopyOnThursday}
                    data-date="isCopyOnThursday"
                    onChange={this.onCheckboxClick}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {window.lang.daysNames[4]}
                  </span>
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCopyOnFriday}
                    data-date="isCopyOnFriday"
                    onChange={this.onCheckboxClick}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {window.lang.daysNames[5]}
                  </span>
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCopyOnSaterday}
                    data-date="isCopyOnSaterday"
                    onChange={this.onCheckboxClick}
                  />
                  <span style={{ marginLeft: "5px" }}>
                    {window.lang.daysNames[6]}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <div style={{ display: "flex" }}>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    onClick={this.onOkClick}
                  >
                    {window.lang.ok}
                  </button>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    style={{ marginLeft: "10px" }}
                    onClick={this.onCancelClick}
                  >
                    {window.lang.cancel2}
                  </button>
                  <button
                    className="ui-btn ui-shadow ui-corner-all"
                    style={{ marginLeft: "10px" }}
                    onClick={this.onRemoveRepetitionClick}
                  >
                    {window.lang.removeRepetition}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

export default class CopyTaskWindow {
  showWindow(trainingItem, doneCallback) {
    ReactDOM.render(
      <CopyTaskV2 trainingItem={trainingItem} doneCallback={doneCallback} />,
      document.getElementById("tsWeekDivReact")
    );
  }
}
